import store from '@/store';
import _ from 'lodash';
import { 
    postToUserServer,
    getLocalStorge,
    getToBaseInfoServer,
    postToItemServer
} from './common';

/**
 * 获取企业联系方式
 */
export async function getPhone(obj){
    return await postToUserServer('/company/get-comp-contact',obj);
}
/**
 * 获取企业联系方式查看数量
 */
export async function getPhoneNum(obj){
    return await postToUserServer('/company/get-n-visited-comp-contact',obj);
}
/**
 * 发起企业认证
 * @param {*} obj 
 */
async function goCompanyVerify(obj){
    let rst = await postToUserServer('/my-company/verify-compinfo', obj);
    // 更新本地的company
    let logedUser = await getLocalStorge('logedUser');
    logedUser.comp = await getCompInfoById(obj.comp_id);
    await store.dispatch('setLogedUser', logedUser);
    return rst;
}
/**
 * 设置企业认证信息
 * @param {*} obj 
 */
async function setCompanyInfo(obj){
    let rst = await postToUserServer('/my-company/set-compinfo', obj);
    // 更新本地的company
    let logedUser = await getLocalStorge('logedUser');
    logedUser.comp = await getCompInfoById(obj.comp_id);
    await store.dispatch('setLogedUser', logedUser);
    return rst;
}
/**
 * 根据id查询企业部门列表
 * @param {*} compId 
 */
async function getDeptsByCompId(compId){
    return await postToUserServer('/my-company/get-depts', {
        comp_id: compId
    });
}
/**
 * 保存部门
 * @param {*} dept 
 */
async function saveDept(dept){
    if(dept.dept_id){
        return await postToUserServer('/my-company/set-dept', dept);
    }else{
        return await postToUserServer('/my-company/add-dept', dept);
    }
}
/**
 * 删除部门
 * @param {*} dept 
 */
export async function delDept(compId,deptId){
    return await postToUserServer('/my-company/delete-dept', {
        comp_id: compId,
        dept_id:deptId
    });
}
/**
 * 管理员换绑短信验证码
 * @param {*} dept 
 */
export async function changeManagerCode(){
    return await postToUserServer('/my-company/get-change-manager-mobile-code',);
}
/**
 * 管理员换绑
 * @param {*} dept 
 */
export async function changeManager(obj){
    return await postToUserServer('/my-company/change-manager', obj);
}
/**
 * 根据id查询企业基本信息
 * @param {*} dept 
 */
export async function getCompBaseInfoById(compId,deptId){
    return await postToUserServer('/my-company/get-comp-baseinfo', {
        comp_id: compId,
    });
}
/**
 * 根据id查询企业信息
 * @param {*} compId 
 */
async function getCompInfoById(compId){
    let rst = await postToUserServer('/my-company/get-compinfo', {
        comp_id: compId
    });
    // 更新本地的company
    let logedUser = await getLocalStorge('logedUser');
    console.log("logedUser",logedUser);
    if (logedUser) {
        logedUser.comp = rst;
        await store.dispatch('setLogedUser', logedUser);
    }
    return rst;
    // if(userComp.id){
    //     return {
    //         //  企业ID
    //         id: userComp.id,
    //         //  企业类型 1家具⼯⼚
    //         // 2家具设计公司
    //         comp_type: userComp.comp_type,
    //         //  企业号
    //         comp_code: userComp.comp_code,
    //         //  企业名称
    //         name: userComp.name,
    //         //  企业简称
    //         short_name: userComp.short_name,
    //         //  所在省份
    //         province: userComp.province,
    //         //  所在城市
    //         city: userComp.city,
    //         //  详细地址
    //         addr: userComp.addr,
    //         //  企业Logo
    //         logo: userComp.logo,
    //         //  联系⼈姓名
    //         contact_name: userComp.contact_name,
    //         //  联系⼈职务
    //         contact_position: userComp.contact_position,
    //         //  联系⼈⼿机号
    //         contact_mobile: userComp.contact_mobile,
    //         //  认证状态 0未申请认证
    //         // 1已申请认证
    //         // 2已通过认证
    //         // 3未通过认证
    //         cert_stat: userComp.cert_stat,
    //         //  认证描述
    //         cert_msg: userComp.cert_msg
    //     };
    // }
    // return null;
}
/**
 * 创建企业带上密码
 * @param {*} obj 
 */
async function createCompanyHasPassword(obj){
    let resp = await postToUserServer('/register-by-mobile/add-comp', {
        // 姓名
        name:obj.name,
        // 企业名称
        compname:obj.comp,
        // 企业类型
        type:obj.type,
        // 登录密码
        passwd:obj.password,
        // 确认登录密码
        passwd_confirmed:obj.cpassword,
    });
    console.log(777,resp);
    let user = Object.assign({},store.state.loginUser,{
        user: resp.user,
        comp:resp.comp
    });
    await store.dispatch('setLogedUser', user);
}
/**
 * 注册时设置企业类型
 * @param {*} type 
 */
async function setCompanyType(type){
    await postToUserServer('/register-by-mobile/sel-comptype', {comptype:type});
}

/**
 * 注册时使用（按企业号查询企业名称）
 * @param {*} obj 
 */
export async function getCompByCode(code){
    return await postToUserServer('/company/get-compname-by-code', {code:code,});
}

/**
 * 根据部门的和公司获得成员列表
 * @param {*} compId 
 * @param {*} deptId 
 */
async function getMembersByDept(obj){
    let users = await postToUserServer('/my-company/get-dept-members', obj);
    // for(let user of users){
    //     if(_.isPlainObject(user.dept)) user.dept=Object.values(user.dept);
    //     if(_.isPlainObject(user.dept)) user.dept=Object.values(user.dept);
    // }
    return users;
}
/**
 * 获取企业资质
 */
export async function getMyCompAptitude(compId){
    return await postToUserServer('/my-company/get-comp-aptitude', {comp_id:compId});
}
/**
 * 获取设计公司服务能力字典
 */
export async function getDesignCompServiceAbility(){
    return await postToUserServer('/my-company/get-dict-design-comp-service-ability');
}
/**
 * 获取工厂服务能力字典
 */
export async function getFactoryServiceAbility(){
    return await postToUserServer('/my-company/get-dict-factory-service-ability');
}
/**
 * 获取工厂工艺能力字典
 */
export async function getFactoryMakeAbility(){
    return await postToUserServer('/my-company/get-dict-factory-make-ability');
}
/**
 * 获取销售公司服务能力字典
 */
// export async function getSaleServiceAbility(){
//     return await postToUserServer('/my-company/get-dict-sale-service-ability');
// }
/**
 * 工厂提交资质认证
 */
export async function submitFactoryAptitude(obj){
    return await postToUserServer('/my-company/verify-aptitude-factory',obj);
}
/**
 * 设计公司提交资质认证
 */
export async function submitDesignAptitude(obj){
    return await postToUserServer('/my-company/verify-aptitude-design-comp',obj);
}
/**
 * 销售公司提交资质认证
 */
export async function submitSaleAptitude(obj){
    return await postToUserServer('/my-company/verify-aptitude-sale-comp',obj);
}
/*--------------------------公用----------------------------*/
/**
 * 获取全国行政区划信息列表(用于地址选择)
 */
export async function getDistrict(code) {
    return await getToBaseInfoServer(`/front/district/${code}`);
  }
/**
 * 获取工厂所在区域字典
 */
export async function getFactoryZones(){
    return await postToUserServer('/company/get-dict-factory-zone');
}
/**
 * 销售公司-工厂列表
 */
export async function getListFactory(obj){
    return await postToItemServer('/factory-market/list-factory',obj);
}

// 批量设置企业类型为规上企业
export async function doInSpecFactory(obj){
    return await postToItemServer('/factory-market/doInSpec-factory',obj);
}
// 批量设置企业类型为非规上企业
export async function unDoInSpecFactory(obj){
    return await postToItemServer('/factory-market/unDoInSpec-factory',obj);
}

/**
 * 销售公司-设计公司列表
 */
export async function getListDesign(obj){
    return await postToUserServer('/design-comp-hall/list-comp',obj);
}

export { createCompanyHasPassword,getCompInfoById,setCompanyType,goCompanyVerify,setCompanyInfo,getDeptsByCompId,saveDept,getMembersByDept }